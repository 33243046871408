import axios from 'axios';
import { API_BASE_URL } from '../config';
import { Recipe } from '../types/Recipe';  // Add this import

export interface ChatResponse {
  message: string;
  recipes: Recipe[];
  suggestedMessages: string[];
}

export const ChatService = {
  sendMessage: async (message: string, county: string, seasonalProduce: string[], currentRecipes: Recipe[], recaptchaToken: string): Promise<ChatResponse> => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/chat`, { 
        message, 
        county, 
        seasonalProduce,
        currentRecipes,
        recaptchaToken
      });
      return response.data;
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  },
};