import React from 'react';
import { Link } from 'react-router-dom';

interface HeaderProps {
  className?: string;
}

const Header: React.FC<HeaderProps> = ({ className = '' }) => {
  return (
    <header className={`bg-[#2C5F2D] text-[#FFF8E7] py-3 h-12 ${className}`}>
      <div className="container mx-auto flex justify-between items-center h-full">
        <Link to="/" className="font-normal text-4xl font-['Italianno',_cursive] tracking-wide">Easy Seasonal Food</Link>
        <nav className="hidden md:flex items-center space-x-6">
          <Link to="/" className="hover:text-[#F2E8CF] transition-colors duration-200">Home</Link>
          <span className="text-[#7A918D]">|</span>
          <a href="https://www.resilientfoodways.com/" target="_blank" rel="noopener noreferrer" className="hover:text-[#F2E8CF] transition-colors duration-200">Read the Manifesto</a>
          <span className="text-[#7A918D]">|</span>
          <a href="https://www.resilientfoodways.com/the-work" target="_blank" rel="noopener noreferrer" className="hover:text-[#F2E8CF] transition-colors duration-200 whitespace-nowrap">More ways AI can help local food systems</a>
          <span className="text-[#7A918D]">|</span>
          <a href="mailto:greg@resilientfoodways.com" className="hover:text-[#F2E8CF] transition-colors duration-200">Contact</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;