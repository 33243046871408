import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCounty } from '../hooks/useCounty';
import { useChatState } from '../hooks/useChatState';
import { RecipeService } from '../services/RecipeService';
import { ChatService } from '../services/ChatService';
import { Recipe } from '../types/Recipe';
import ChatPane from '../components/ChatPane';
import RecipePane from '../components/RecipePane';
import SeasonalProduceSection from '../components/SeasonalProduceSection';
import MobileNavigation from '../components/MobileNavigation';
import LoadingFact from '../components/LoadingFact';
import { ChatResponse } from '../services/ChatService';

declare global {
  interface Window {
    grecaptcha: {
      enterprise: {
        ready: (callback: () => void) => void;
        execute: (siteKey: string, options: { action: string }) => Promise<string>;
      };
    };
  }
}

const ChatRecipeView: React.FC = () => {
  const { county } = useParams<{ county?: string }>();
  const { setCounty } = useCounty();
  const { messages, addMessage } = useChatState();
  const [recipes, setRecipes] = useState<Recipe[] | null>(null);
  const [seasonalProduce, setSeasonalProduce] = useState<string[]>([]);
  const [activeView, setActiveView] = useState<'recipe' | 'chat' | 'produce'>('recipe');
  const [isLoading, setIsLoading] = useState(true);
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [isUpdatingRecipes, setIsUpdatingRecipes] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showLoadingFact, setShowLoadingFact] = useState(false);
  const [suggestedMessages, setSuggestedMessages] = useState<string[]>([]);

  const executeRecaptcha = useCallback(async (action: string) => {
    if (!window.grecaptcha) {
      console.error('reCAPTCHA not yet available');
      return null;
    }

    return new Promise<string>((resolve, reject) => {
      window.grecaptcha.enterprise.ready(async () => {
        try {
          const token = await window.grecaptcha.enterprise.execute(
            process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
            { action }
          );
          resolve(token);
        } catch (error) {
          reject(error);
        }
      });
    });
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (county && !initialDataFetched) {
      setCounty(county);
      fetchInitialData(county);
      setInitialDataFetched(true);
    }
  }, [county, initialDataFetched, setCounty]);

  const fetchInitialData = async (county: string) => {
    setIsLoading(true);
    setShowLoadingFact(true);

    try {
      const produce = await RecipeService.getSeasonalProduce(county);
      setSeasonalProduce(produce.items);
      
      const recipesData = await RecipeService.getRecipes(county, produce.items);
 
      setRecipes(recipesData.recipes);
      setSuggestedMessages(recipesData.suggestedMessages);

      // Simulate a delay to show the loading fact (adjust as needed)
      await new Promise(resolve => setTimeout(resolve, 2000));

      addMessage({ 
        text: `Here are some seasonal recipes for ${county} based on the current produce.`, 
        sender: 'ai' 
      });
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
      addMessage({ text: 'Sorry, there was an error fetching the initial data.', sender: 'ai' });
    } finally {
      setIsLoading(false);
      setShowLoadingFact(false);
    }
  };

  const handleSendMessage = async (message: string) => {
    addMessage({ text: message, sender: 'user' });
    setIsUpdatingRecipes(true);
    setSuggestedMessages([]);
    
    if (isMobile) {
      setTimeout(() => {
        setActiveView('recipe');
        setShowLoadingFact(true);
      }, 2000);
    } else {
      setShowLoadingFact(true);
    }

    try {
      const recaptchaToken = await executeRecaptcha('send_message');
      if (!recaptchaToken) {
        throw new Error('Failed to execute reCAPTCHA');
      }
      const response: ChatResponse = await ChatService.sendMessage(message, county || '', seasonalProduce, recipes || [], recaptchaToken);
      addMessage({ text: response.message, sender: 'ai' });
      setRecipes(response.recipes);
      setSuggestedMessages(response.suggestedMessages);
      if (!isMobile) {
        setActiveView('recipe');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      addMessage({ text: 'Sorry, there was an error processing your message. Please try again later.', sender: 'ai' });
    } finally {
      setIsUpdatingRecipes(false);
      setShowLoadingFact(false);
    }
  };

  const shouldShowLoadingFact = useCallback(() => {
    return showLoadingFact;
  }, [showLoadingFact]);

  return (
    <div className="flex flex-col h-[calc(100vh-6rem)]">
      <MobileNavigation activeView={activeView} onViewChange={setActiveView} />
      <div className="flex-grow overflow-hidden">
        {/* Desktop-only content */}
        <div className="hidden md:flex h-full">
          <div className="w-1/5 bg-green-50 overflow-hidden">
            <div className="h-full overflow-y-auto">
              <SeasonalProduceSection county={county} produce={seasonalProduce} />
            </div>
          </div>
          <div className="w-3/5 flex flex-col relative overflow-hidden">
            {shouldShowLoadingFact() && (
              <div className="absolute inset-0 bg-white bg-opacity-90 z-10 flex items-center justify-center">
                <LoadingFact isLoading={true} />
              </div>
            )}
            <RecipePane recipes={recipes || []} isLoading={isLoading || isUpdatingRecipes} />
          </div>
          <div className="w-1/5 border-l border-gray-200 bg-gray-50 flex flex-col">
            <ChatPane 
              onSendMessage={handleSendMessage} 
              messages={messages} 
              suggestedMessages={suggestedMessages} // Add this line
            />
          </div>
        </div>
        
        {/* Mobile content */}
        <div className="md:hidden h-full bg-[#FFF8E7]">
          {activeView === 'recipe' && (
            <div className="relative h-full">
              {shouldShowLoadingFact() && (
                <div className="absolute inset-0 bg-[#FFF8E7] bg-opacity-90 z-10 flex items-center justify-center">
                  <LoadingFact isLoading={true} />
                </div>
              )}
              <RecipePane recipes={recipes || []} isLoading={isLoading || isUpdatingRecipes} />
            </div>
          )}
          {activeView === 'chat' && (
            <ChatPane 
              onSendMessage={handleSendMessage} 
              messages={messages} 
              suggestedMessages={suggestedMessages} // Add this line
            />
          )}
          {activeView === 'produce' && (
            <SeasonalProduceSection county={county} produce={seasonalProduce} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatRecipeView;