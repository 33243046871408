import React from 'react';

interface SuggestedMessagesProps {
  messages: string[];
  onSendMessage: (message: string) => void;
}

const SuggestedMessages: React.FC<SuggestedMessagesProps> = ({ messages, onSendMessage }) => {
  return (
    <div className="mt-4">
      <h3 className="text-sm font-semibold text-gray-600 mb-2">Suggested messages:</h3>
      <div className="flex flex-wrap gap-2">
        {messages.map((message, index) => (
          <button
            key={index}
            onClick={() => onSendMessage(message)}
            className="bg-green-100 hover:bg-green-200 text-green-800 py-1 px-3 rounded-full text-sm transition duration-200"
          >
            {message}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SuggestedMessages;