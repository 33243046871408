import React from 'react';

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className = '' }) => {
  return (
    <footer className={`bg-[#2C5F2D] text-[#FFF8E7] p-4 ${className}`}>
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div>&copy; {new Date().getFullYear()} Resilient Foodways LLC</div>
        <div className="flex flex-col md:flex-row items-center mt-2 md:mt-0 text-xs">
          <span>
            This site is protected by reCAPTCHA and the Google
            {' '}
            <a href="https://policies.google.com/privacy" className="underline hover:text-[#FFB84C]" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            {' '}and{' '}
            <a href="https://policies.google.com/terms" className="underline hover:text-[#FFB84C]" target="_blank" rel="noopener noreferrer">Terms of Service</a>
            {' '}apply.
          </span>
          <span className="hidden md:inline mx-2 text-[#7A918D]">|</span>
          <a href="mailto:greg@resilientfoodways.com" className="hover:text-[#FFB84C] transition-colors duration-200 mt-2 md:mt-0">Contact</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;