import React, { useState } from 'react';

interface SeasonalProduceSectionProps {
  county: string | undefined;
  produce: string[];
}

const SeasonalProduceSection: React.FC<SeasonalProduceSectionProps> = ({ county, produce }) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);

  if (!county || produce.length === 0) {
    return null;
  }

  const today = new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric' });

  const handleItemClick = (item: string) => {
    setActiveItem(activeItem === item ? null : item);
  };

  return (
    <div className="p-4 bg-[#FFF8E7]">
      <h2 className="text-xl font-semibold mb-4 text-[#2C5F2D]">
        Seasonal Produce
      </h2>
      <p className="text-sm text-[#4A3728] mb-2">
        in <span className="font-bold">{county}</span> around <span className="font-bold">{today}</span>
      </p>
      <ul className="space-y-2">
        {produce.map((item, index) => (
          <li 
            key={index} 
            className="text-sm text-[#4A3728] cursor-pointer hover:bg-[#FFB84C] transition-colors duration-200 p-2 rounded"
            onClick={() => handleItemClick(item)}
          >
            {item}
            {activeItem === item && (
              <p className="text-xs text-[#CD5C5C] mt-1">
                Tip: Try asking the chat about recipes using {item}!
              </p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SeasonalProduceSection;