import React from 'react';

interface MobileNavigationProps {
  activeView: 'recipe' | 'chat' | 'produce';
  onViewChange: (view: 'recipe' | 'chat' | 'produce') => void;
}

const MobileNavigation: React.FC<MobileNavigationProps> = ({ activeView, onViewChange }) => {
  return (
    <div className="bg-[#2C5F2D] text-[#FFF8E7] flex justify-around items-center h-14 md:hidden">
      <button 
        className={`px-4 py-2 ${activeView === 'recipe' ? 'font-bold border-b-2 border-[#F2E8CF]' : ''}`}
        onClick={() => onViewChange('recipe')}
      >
        Recipes
      </button>
      <button 
        className={`px-4 py-2 ${activeView === 'chat' ? 'font-bold border-b-2 border-[#F2E8CF]' : ''}`}
        onClick={() => onViewChange('chat')}
      >
        Chat
      </button>
      <button 
        className={`px-4 py-2 ${activeView === 'produce' ? 'font-bold border-b-2 border-[#F2E8CF]' : ''}`}
        onClick={() => onViewChange('produce')}
      >
        Seasonal Produce
      </button>
    </div>
  );
};

export default MobileNavigation;