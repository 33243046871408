import React, { useState, useRef, useEffect } from 'react';
import SuggestedMessages from './SuggestedMessages';

interface ChatPaneProps {
  onSendMessage: (message: string) => void;
  messages: { text: string; sender: 'user' | 'ai' }[];
  suggestedMessages: string[];
}

const ChatPane: React.FC<ChatPaneProps> = ({ onSendMessage, messages, suggestedMessages }) => {
  const [input, setInput] = useState('');
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim()) {
      try {
        await onSendMessage(input.trim());
        setInput('');
        setError(null);
      } catch (error) {
        setError('Failed to send message. Please try again.');
      }
    }
  };

  return (
    <div className="flex flex-col h-full w-full bg-[#F8F4E3]">
      <div className="flex-grow overflow-y-auto p-4 w-full">
        {messages.map((message, index) => (
          <div key={index} className={`mb-4 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
            <span
              className={`inline-block p-2 rounded-lg ${
                message.sender === 'user' ? 'bg-[#2C5F2D] text-[#F8F4E3]' : 'bg-[#7A918D] text-[#F8F4E3]'
              }`}
            >
              {message.text}
            </span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      {suggestedMessages.length > 0 && (
        <div className="px-4">
          <SuggestedMessages messages={suggestedMessages} onSendMessage={onSendMessage} />
        </div>
      )}
      <div className="p-4 border-t border-[#7A918D] bg-[#F8F4E3] w-full">
        {error && <p className="text-red-500 mb-2">{error}</p>}
        <form onSubmit={handleSubmit} className="flex w-full">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className="flex-grow p-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-[#2C5F2D] text-[#4A3728] bg-white"
          />
          <button
            type="submit"
            className="bg-[#2C5F2D] text-[#F8F4E3] p-2 rounded-r-md hover:bg-[#234C24] focus:outline-none focus:ring-2 focus:ring-[#2C5F2D] transition duration-200"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChatPane;