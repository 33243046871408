import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';

interface CountyInputProps {
  onSubmit: (county: string) => void;
}

const CountyInput: React.FC<CountyInputProps> = ({ onSubmit }) => {
  const [county, setCounty] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [selectedCounty, setSelectedCounty] = useState('');
  const navigate = useNavigate();

  const fetchSuggestions = useCallback(async (value: string) => {
    if (value.length >= 3) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/county-suggestions/${value}`);
        setSuggestions(response.data.suggestions);
        if (response.data.suggestions.length === 0) {
          setSuggestions([`No US Counties match '${value}'`]);
        }
      } catch (error) {
        console.error('Error fetching county suggestions:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCounty(value);
    setSelectedCounty('');
    setError('');
    fetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion: string) => {
    if (suggestion.startsWith('No US Counties match')) return;
    setCounty(suggestion);
    setSelectedCounty(suggestion);
    setSuggestions([]);
    setError(''); // Clear any existing error when a valid county is selected
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedCounty) {
      onSubmit(selectedCounty);
      navigate(`/chat/${encodeURIComponent(selectedCounty)}`);
    } else {
      setError('Please select a valid county from the dropdown.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="relative">
        <input
          type="text"
          value={county}
          onChange={handleInputChange}
          placeholder="Enter your county"
          className="w-full p-4 border-2 border-[#7A918D] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2C5F2D] transition duration-200"
        />
        {suggestions.length > 0 && (
          <ul className="absolute w-full mt-1 bg-white border-2 border-[#F2E8CF] rounded-lg shadow-lg z-10 max-h-60 overflow-y-auto">
            {suggestions.map((suggestion, index) => (
              <li 
                key={index} 
                onClick={() => handleSuggestionClick(suggestion)} 
                className={`p-3 ${suggestion.startsWith('No US Counties match') ? 'text-gray-500 cursor-not-allowed' : 'hover:bg-[#F2E8CF] cursor-pointer'} transition duration-200`}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      <button 
        type="submit" 
        className="w-full mt-4 p-4 bg-[#2C5F2D] text-white rounded-lg hover:bg-[#234C24] transition duration-200 focus:outline-none focus:ring-2 focus:ring-[#7A918D]"
      >
        Explore Seasonal Recipes
      </button>
    </form>
  );
};

export default CountyInput;
