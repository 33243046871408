import React, { useState, useEffect } from 'react';
import didYouKnowFacts from '../data/didYouKnowFacts';

interface LoadingFactProps {
  isLoading: boolean;
}

const LoadingFact: React.FC<LoadingFactProps> = ({ isLoading }) => {
  const [fact, setFact] = useState('');

  useEffect(() => {
    if (isLoading) {
      setFact(didYouKnowFacts[Math.floor(Math.random() * didYouKnowFacts.length)]);
    }
  }, [isLoading]);

  if (!isLoading || fact === '') {
    return null;
  }

  return (
    <div className="text-center p-4 bg-white rounded-lg shadow-md max-w-md">
      <div className="flex justify-center mb-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-500"></div>
      </div>
      <p className="font-bold mb-2 text-green-700">DID YOU KNOW?</p>
      <p className="text-gray-700">{fact}</p>
    </div>
  );
};

export default LoadingFact;