import axios from 'axios';
import { API_BASE_URL } from '../config';
import { Recipe } from '../types/Recipe';  // Add this import

export const RecipeService = {
  getSeasonalProduce: async (county: string) => {
    const response = await axios.get(`${API_BASE_URL}/api/v1/seasonal-produce/${encodeURIComponent(county)}`);
    return response.data;
  },
  getRecipes: async (county: string, seasonalProduce: string[]): Promise<{ recipes: Recipe[], suggestedMessages: string[] }> => {
    const response = await axios.post(`${API_BASE_URL}/api/v1/recipes`, { county, seasonalProduce });
    return {
      recipes: response.data.recipes,
      suggestedMessages: response.data.suggestedMessages || []
    };
  },
};