import { useState } from 'react';

interface Message {
  text: string;
  sender: 'user' | 'ai';
}

export const useChatState = () => {
  const [messages, setMessages] = useState<Message[]>([]);

  const addMessage = (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  return { messages, addMessage };
};