import React, { useState } from 'react';
import { Recipe } from '../types/Recipe';

interface RecipePaneProps {
  recipes: Recipe[];
  isLoading: boolean;
  className?: string;
}

const RecipePane: React.FC<RecipePaneProps> = ({ recipes, isLoading, className }) => {
  const [expandedRecipe, setExpandedRecipe] = useState<string | null>(null);
  const [copiedRecipe, setCopiedRecipe] = useState<string | null>(null);

  const toggleRecipe = (id: string) => {
    setExpandedRecipe(expandedRecipe === id ? null : id);
  };

  const copyRecipeToClipboard = (e: React.MouseEvent, recipe: Recipe) => {
    e.stopPropagation();
    const recipeText = `
Recipe: ${recipe.name}

Serving Size: ${recipe.serving_size}

Ingredients:
${recipe.ingredients.map(ingredient => `- ${ingredient}`).join('\n')}

Instructions:
${recipe.instructions.map((instruction, index) => `${index + 1}. ${instruction}`).join('\n')}
    `.trim();

    navigator.clipboard.writeText(recipeText).then(() => {
      setCopiedRecipe(recipe.id);
      setTimeout(() => setCopiedRecipe(null), 2000);
    });
  };

  return (
    <div className={`bg-[#F8F4E3] flex flex-col h-full ${className}`}>
      <h2 className="text-2xl font-bold p-4 bg-[#7A918D] sticky top-0 z-10 text-[#F8F4E3]">Seasonal Recipes</h2>
      <div className="flex-grow overflow-y-scroll">
        {isLoading ? (
          <p className="p-4 text-[#4A3728]">Loading recipes...</p>
        ) : Array.isArray(recipes) && recipes.length > 0 ? (
          <ul>
            {recipes.map((recipe) => (
              <li key={recipe.id} className="border-b border-[#7A918D] last:border-b-0">
                <div 
                  className={`p-4 cursor-pointer transition duration-150 ease-in-out ${
                    expandedRecipe === recipe.id 
                      ? 'bg-[#F2E8CF] hover:bg-[#E8DFC5]' 
                      : 'hover:bg-[#F2E8CF]'
                  }`}
                  onClick={() => toggleRecipe(recipe.id)}
                >
                  <div className="flex justify-between items-center">
                    <h3 className="text-xl font-semibold text-[#2C5F2D]">{recipe.name}</h3>
                    <div className="flex items-center">
                      {expandedRecipe === recipe.id && (
                        <button
                          onClick={(e) => copyRecipeToClipboard(e, recipe)}
                          className="text-[#7A918D] hover:text-[#2C5F2D] focus:outline-none transition duration-200 mr-2 text-sm"
                        >
                          {copiedRecipe === recipe.id ? 'Copied!' : 'Copy Recipe'}
                        </button>
                      )}
                      <span className="text-[#2C5F2D] hover:text-[#234C24] transition duration-200">
                        {expandedRecipe === recipe.id ? 'Hide Details' : 'Show Details'}
                      </span>
                    </div>
                  </div>
                  {expandedRecipe === recipe.id && (
                    <div className="mt-4 text-[#4A3728]">
                      <h4 className="text-lg font-semibold mt-2">Ingredients:</h4>
                      <ul className="list-disc list-inside">
                        {recipe.ingredients.map((ingredient, index) => (
                          <li key={index}>{ingredient}</li>
                        ))}
                      </ul>
                      <h4 className="text-lg font-semibold mt-4">Serving Size:</h4>
                      <p className="mb-4">
                        {recipe.serving_size || 'Serving size not available'}
                      </p>
                      <h4 className="text-lg font-semibold mt-4">Instructions:</h4>
                      <ol className="list-decimal list-inside">
                        {recipe.instructions.map((instruction, index) => (
                          <li key={index} className="mb-2">{instruction}</li>
                        ))}
                      </ol>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="p-4 text-[#4A3728]">No recipes available. Try chatting to get some recipe suggestions!</p>
        )}
      </div>
    </div>
  );
};

export default RecipePane;