const didYouKnowFacts: string[] = [
  // Positive Facts
  "Eating seasonal produce means your food is fresher, more flavorful, and packed with nutrients!",
  "Seasonal fruits and veggies are often grown closer to home, reducing carbon emissions from transportation.",
  "When you eat seasonally, you support local farmers and strengthen your community’s food system.",
  "Local, seasonal produce is often less expensive because it doesn’t have to travel as far to reach you.",
  "Many seasonal foods are harvested at their nutritional peak, meaning they’re richer in vitamins and minerals.",
  "Eating seasonally can help reduce the need for chemical preservatives and energy-intensive storage methods.",
  "A diverse, seasonal diet helps you naturally eat a wider variety of nutrients throughout the year.",
  "By choosing seasonal produce, you're helping preserve farmland and open spaces in your region.",
  "Seasonal foods are often tailored to the season’s needs—think cooling fruits in the summer and hearty root veggies in the winter.",
  "Seasonal eating reduces food waste, as crops are grown and harvested at their optimal times.",
  "Seasonal diets have been shown to lower your environmental footprint and contribute to more sustainable agriculture.",
  "By eating seasonally, you can try new recipes and discover flavors you might not experience otherwise!",
  "Supporting local, seasonal food systems encourages biodiversity and the growth of traditional and heirloom varieties.",
  "Seasonal foods are often less processed, meaning fewer additives and more natural goodness.",
  "Local and seasonal food systems are more resilient to global disruptions in the food supply chain.",
  "Eating seasonally helps reduce the demand for energy-intensive greenhouse-grown or imported produce.",
  "Studies suggest that eating a variety of seasonal produce can improve gut health and support your immune system.",
  "Seasonal eating brings people together—many cultures have festivals and celebrations tied to seasonal harvests.",
  "Choosing seasonal produce helps lower food miles, which means fewer fossil fuels are used in getting food to your plate.",

  // Negative Facts
  "Many tomatoes in grocery stores are picked green and ripened with ethylene gas, which can affect their flavor and texture.",
  "Out-of-season produce often travels thousands of miles to reach your grocery store, losing freshness and nutrients along the way.",
  "Industrial farming practices sometimes rely on monocultures, which can deplete the soil and harm biodiversity.",
  "Imported fruits and vegetables are often harvested before they fully ripen, which can lead to lower nutritional content and less flavor.",
  "Food grown far from your home may be stored for weeks or even months before reaching store shelves, reducing its nutrient density.",
  "Many out-of-season fruits and vegetables are grown in energy-intensive greenhouses, which can increase their environmental footprint.",
  "Industrial food production often prioritizes uniformity and durability over taste, meaning some varieties are bred for shipping, not flavor.",
  "The global food system relies heavily on fossil fuels—not just for transportation, but also for synthetic fertilizers and pesticides.",
  "Supermarket produce is often treated with waxes, preservatives, or other chemicals to extend shelf life, which can impact taste and quality.",
  "The year-round availability of produce can mask the true cost of food, as out-of-season items often come with higher environmental and social costs.",

  // Resilience Facts
  "Local food systems are less vulnerable to global supply chain disruptions, which can leave shelves empty during times of crisis.",
  "Supporting local farms strengthens food security, making communities less reliant on distant imports that can be delayed or restricted during emergencies.",
  "A resilient local food system can help avoid price spikes caused by global events, ensuring affordable and stable access to fresh produce.",
  "Local food systems are more adaptable in times of natural disasters, allowing communities to recover faster with nearby sources of food.",
  "By supporting local agriculture, communities are less affected by international trade disputes or political instability that can disrupt food availability."

];

export default didYouKnowFacts;